.nav {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%) translateY(-0%);
  z-index: 20;
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid var(--grey);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.nav a {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  margin: 20px;
  transition: 0.3s;
}
.nav.active {
  transform: translateX(-50%) translateY(-123px);
}

nav > button {
  font-size: 27px;
  font-weight: 600;
  line-height: 0px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -28px;
  background-color: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(5px);
  width: 50px;
  z-index: 19;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid var(--grey);
  color: white;
  text-align: center;
  cursor: pointer;
  padding: 13px 0px;
}
.nav.active > button {
  background-color: white;
  color: black;
}
.nav a.active {
  box-shadow: 0 0 30px #7f50f5;
  background-color: white;
}
.nav a.active * {
  color: black;
}

@media (max-width: 600px) {
    nav > button{
        bottom: -28px;
    }
    .nav {
        background-color: #683FD0;
    }
  .nav.active {
    transform: translateX(-50%) translateY(-93px);
  }

  .nav {
    width: calc(100% - 40px);
    position: fixed;
    top: 20px;
    justify-content: space-between;
  }
  .nav a {
    margin: 10px;
  }
}

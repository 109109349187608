.slider > div > div > div {
  padding: 20px;
}
.art.slider > div > div > div {
  padding: 10px;
  padding-bottom: 20px;
}
.slider p {
  margin-bottom: 0px;
}
.slick-dots {
  bottom: -10px;
}
.slick-dots li button:before {
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.5;
  transition: 0.3s;
  color: white;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  font-size: 15px;
  color: var(--secondary-color);
}

.slick-slide > div > div {
  min-height: 160px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--grey);
  position: relative;
  overflow: hidden;
  transition: 0.7s;
  box-shadow: 0 5px 20px rgba(0 0 0/40%);
}
.art .slick-slide > div > div {
  max-width: 200px;
  height: 250px;
  padding: 0px;
  margin: 0px auto;
  display: block !important;
}

.art .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

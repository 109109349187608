.skills_flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.skills_flex > div {
  width: calc(33.33% - 10px);

  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--grey);
  position: relative;
  overflow: hidden;
  transition: 0.7s;
  box-shadow: 0 5px 20px rgba(0 0 0/40%);
}
.skills_flex > div:nth-child(3) > div > p {
  white-space: nowrap;
}
.skills_flex div p {
  margin-bottom: 0px;
}
.skills_flex > div:after {
  position: absolute;
  content: "";
  top: -200px;
  left: -120%;
  transform: rotate(-45deg);
  width: 900px;
  height: calc(100% + 200px);
  background: rgb(50, 29, 103);
  background: linear-gradient(
    0deg,
    rgba(50, 29, 103, 0) 0%,
    rgba(189, 163, 255, 1) 50%,
    rgba(50, 29, 103, 0) 100%
  );
  transition: 0.7s;
  opacity: 0.1;
}
.skills_flex > div:hover:after {
  left: 70%;
}
.skills_flex > div:hover {
  box-shadow: 0 5px 20px rgba(0 0 0/10%);
}

@media (max-width: 1100px) {
  .skills_flex > div {
    width: calc(50% - 20px);
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--grey);
    position: relative;
    overflow: hidden;
    transition: 0.7s;
    box-shadow: 0 5px 20px rgba(0 0 0/40%);
  }
  .skills_flex > div:nth-child(3) {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .skills_flex > div {
    width: 100%;
  }
}


#layout {
  flex: 1;
  position: relative;
}

.layout_bg {
  position: absolute;
  content: "";
  inset: 0;
  height: 100%;
  width: 100%;
  background: rgb(30, 30, 30);
  background: linear-gradient(
    0deg,
    rgba(30, 30, 30, 1) 0%,
    rgba(91, 26, 255, 1) 50%,
    rgba(30, 30, 30, 1) 100%
  );
}
.layout_animation {
  position: fixed;
  content: "";
  inset: 0;
  height: 300px;
  width: 100%;
  background: rgb(50, 29, 103);
  background: linear-gradient(
    0deg,
    rgba(50, 29, 103, 0) 0%,
    rgba(189, 163, 255, 1) 50%,
    rgba(50, 29, 103, 0) 100%
  );

  transform: translatey(-300px);
  animation: layout_anim 13s infinite ease-in-out;
}
@keyframes layout_anim {
  30% {
    transform: translatey(100vh);
  }
  100% {
    transform: translatey(100vh);
  }
}

.wrapper {
  padding: 30px;
  width: 100%;

  position: relative;
  inset: 0;
  z-index: 4;
  scroll-behavior: smooth;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}
.content {
  width: min(100%, 1366px);
  margin-inline: auto;
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) {
  .content {
    height: 100%;
    justify-content: space-between;
  }
  .content > div {
    flex: 1;
  }
  .wrapper {
    padding: 20px;
  }
}

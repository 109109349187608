.card {
  background-color: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid var(--grey);

  margin: 7em 0px;
  position: relative;
  z-index: 10;
  width: 100%;
}
